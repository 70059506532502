import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

// For more info on the @pwabuilder/pwainstall component click here https://github.com/pwa-builder/pwa-install
import '@pwabuilder/pwainstall';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/icon/library.default';
import 'file-saver';

import {default as cpe} from '../json/cpe.json';

import { styles } from '../styles/shared-styles';
import saveAs from 'file-saver';

@customElement('app-home')
export class AppHome extends LitElement {

  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property({type: String}) message = 'Generador de configuración';
  @property({type: Number}) latitude: Number|null = null;
  @property({type: Number}) longitude: Number|null = null;
  @property({type: JSON}) cpeConfig: any = cpe;

  static get styles() {
    return [
      styles,
      css`
      #welcomeBar {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--sl-color-primary-600);
      }

      #welcomeCard,
      #infoCard {
        padding: 18px;
        padding-top: 0px;
      }

      sl-input {
        margin-top: 20px;
      }

      sl-card::part(footer) {
        display: flex;
        justify-content: flex-end;
      }

      @media(min-width: 750px) {
        sl-card {
          width: 70vw;
        }
      }


      @media (horizontal-viewport-segments: 2) {
        #welcomeBar {
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
        }

        #welcomeCard {
          margin-right: 64px;
        }
      }
    `];
  }

  constructor() {
    super();
  }

  async firstUpdated() {
    console.log('AVATEL Wifi');
  }

  share() {
    if ((navigator as any).share) {
      (navigator as any).share({
        title: 'AVATEL Wifi',
        text: 'Descarga la APP para generar la configuración WIFI',
        url: window.location,
      });
    }
  }


  getCoords() {
    if (navigator.geolocation) {
      let self = this;
      navigator.geolocation.getCurrentPosition(function(position) {
        self.latitude = position.coords.latitude;
        self.longitude = position.coords.longitude;
      });
    } else {
      alert('Este dispositivo no soporta la localización.');
    }
  }

  generate() {
    if (!this.latitude || !this.longitude) {
      alert('Debe rellenar las coordenadas');
      return;
    }
    this.cpeConfig.device_props.systemDeviceLocLatitude = this.latitude.toString();
    this.cpeConfig.device_props.systemDeviceLocLongitude = this.longitude.toString();
    let jsonFile = new Blob([JSON.stringify(this.cpeConfig)], {type: "application/json"});
    saveAs(jsonFile, 'cpe-config.json');
  }

  render() {
    return html`
      <app-header></app-header>

      <main>
        <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <div slot="header">
              <h2>
              ${this.message}
              <!-- ${'share' in navigator
              ? html`<sl-button variant="default" @click="${this.share}" outline>📨 Compartir</sl-button>`
              : null} -->
            </h2>
            </div>
            <sl-input placeholder="Latitud" size="medium" pill value="${this.latitude}"></sl-input>
            <sl-input placeholder="Longitud" size="medium" pill value="${this.longitude}"></sl-input>
            <sl-button variant="primary" outline style="margin-top:10px;margin-bottom:50px;" @click="${this.getCoords}">Obtener coordenadas</sl-button>
            <sl-button type="submit" variant="primary" size="large" style="width: 100%;margin-top:100px;" outline @click="${this.generate}">Generar</sl-button>
          </sl-card>
        </div>
      </main>
    `;
  }
}
